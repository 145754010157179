import { Component, OnInit } from '@angular/core';
import { Mail } from 'src/app/models/mail';
import { MailingService } from 'src/app/services/mailing.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  contact: Mail = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    description: ''
  }

  errors = {
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
    description: false
  }

  errorMessage = ''

  constructor(private mailingService: MailingService) { }

  ngOnInit() {
  }

  checkContact() {
    let isThereAnError = ''
    let checkEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if(!this.contact.firstName) {
      this.errors.firstName = true
      isThereAnError += '<p>Por favor ingrese su primer nombre</p>'
    }else{
      this.errors.firstName = false
    }

    if(!this.contact.lastName) {
      this.errors.lastName = true
      isThereAnError += '<p>Por favor ingrese su apellido</p>'
    }else{
      this.errors.lastName = false
    }

    if(!this.contact.email) {
      this.errors.email = true
      isThereAnError += '<p>Por favor ingrese su correo electrónico</p>'
    }else{
      this.errors.email = false
    }

    if(!checkEmail.test(this.contact.email)) {
      this.errors.email = true
      isThereAnError += '<p>Por favor ingrese un correo electrónico válido</p>'
    }else{
      this.errors.email = false
    }

    if(!this.contact.phoneNumber) {
      this.errors.phoneNumber = true
      isThereAnError += '<p>Por favor ingrese su numero telefónico</p>'
    }else{
      this.errors.phoneNumber = false
    }

    if(!this.contact.description) {
      this.errors.description = true
      isThereAnError += '<p>Por favor ingrese un mensaje</p>'
    }else{
      this.errors.description = false
    }

    if(isThereAnError) {
      this.errorMessage = isThereAnError
      return
    }else {
      this.sendContact()
    }
  }

  resetFields() {
    this.contact = {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      description: ''
    }

    this.errors = {
      firstName: false,
      lastName: false,
      email: false,
      phoneNumber: false,
      description: false
    }
  }

  sendContact() {
    this.mailingService.sendContact(this.contact).subscribe(
      res => {
        console.log(res)
      }
    )
    alert('Su información ha sido enviada exitosamente!')
    this.resetFields()
  }

}
