import { Component, OnInit } from '@angular/core';
import { DbService } from 'src/app/services/db.service';
import { Products } from 'src/app/models/products';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  products: Array<Products> = []

  constructor(private dbService: DbService) { }

  ngOnInit() {
    this.products = this.dbService.getProducts()
  }

}
