import { Component, OnInit } from '@angular/core';
import { Products } from 'src/app/models/products';
import { ActivatedRoute, Router } from '@angular/router';
import { DbService } from 'src/app/services/db.service';
import { Mail } from 'src/app/models/mail';
import { MailingService } from 'src/app/services/mailing.service';
import { Product } from 'src/app/models/product';

@Component({
  selector: 'app-cotization',
  templateUrl: './cotization.component.html',
  styleUrls: ['./cotization.component.css']
})
export class CotizationComponent implements OnInit {

  mail: Mail = {
    firstName: '',
    lastName: '',
    email: '',
    productTitle: '',
    description: ''
  }

  errors = {
    firstName: false,
    lastName: false,
    email: false,
    description: false
  }

  errorMessage = ''

  product: Products | Product

  constructor(private activatedRoute: ActivatedRoute, private dbService: DbService, private mailingService: MailingService, private router: Router) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(paramMap => {
      const brand = paramMap.get('brand')
      const productName = paramMap.get('title')
      const id = Number(paramMap.get('id'))
      console.log(brand, productName)
      if(!brand) {
        this.product = this.dbService.getProductById(id)
      }else{
        this.product = this.dbService.getProductByBrandAndName(brand, productName)
      }
    })
  }

  checkCotization() {
    let isThereAnError = ''
    let checkEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if(!this.mail.firstName) {
      this.errors.firstName = true
      isThereAnError += '<p>Por favor ingrese su primer nombre.</p>'
    }else{
      this.errors.firstName = false
    }

    if(!this.mail.lastName) {
      this.errors.lastName = true
      isThereAnError += '<p>Por favor ingrese su apellido.</p>'
    }else{
      this.errors.lastName = false
    }

    if(!this.mail.email) {
      this.errors.email = true
      isThereAnError += '<p>Por favor ingrese su correo electrónico.</p>'
    }else{
      this.errors.email = false
    }

    if(!checkEmail.test(this.mail.email)) {
      this.errors.email = true
      isThereAnError += '<p>Por favor ingrese un correo electrónico válido.</p>'
    }else{
      this.errors.email = false
    }

    if(!this.mail.description) {
      this.errors.description = true
      isThereAnError += '<p>Por favor ingrese una descripción del producto que desea cotizar.</p>'
    }else{
      this.errors.description = false
    }

    if(isThereAnError) {
      this.errorMessage = isThereAnError
      return
    }else {
      this.sendCotization()
    }
  }

  sendCotization() {
    this.mail.productTitle = this.product.title
    this.mailingService.sendEmail(this.mail).subscribe(
      res => {
        console.log(res)
      }
    )
    this.router.navigate(['/products'])
  }

}