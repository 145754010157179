import { Injectable } from '@angular/core';
import { Products } from '../models/products';
import { Product } from '../models/product';

@Injectable({
  providedIn: 'root'
})
export class DbService {

  products: Array<Products> = [
    {
      id:1,
      img: 'assets/img/WhatsApp Image 2020-10-15 at 21.54.36.jpeg',
      title: 'Generadores Eléctricos',
      description: 'Proveemos generadores desde 1.0 kw hasta 1000 kw en configuración monofásica y trifásica.',
      newProduct: false,
      moreProducts: false
    },
    {
      id:2,
      img: 'assets/img/Materiales_Electricos.png',
      title: 'Materiales Eléctricos',
      description: 'Proveemos materiales eléctricos para proyectos de vivienda e industria.',
      newProduct: false,
      moreProducts: false
    },
    {
      id:3,
      img: 'assets/img/Tipo_poste.jpg',
      title: 'Transformadores Tipo Poste',
      description: 'Proveemos transformadores desde 15 kva hasta 100 kva en voltajes de 13.8 kv y 34.5 kv.',
      newProduct: false,
      moreProducts: false
    },
    {
      id:4,
      img: 'assets/img/Pedestal.jpg',
      title: 'Transformadores Tipo Pedestal',
      description: 'Proveemos transformadores desde 25 kva hasta 1000 kva en voltajes de 13.8 kv y 34.5 kv monofásicos.',
      newProduct: false,
      moreProducts: false
    },
    {
      id:5,
      img: 'assets/img/Watergen_Generador.jpg',
      title: 'Generadores de Agua',
      description: 'Proveemos generadores de agua. Somos distribuidores de la marca Watergen para Honduras.',
      newProduct: false,
      moreProducts: true
    },
    {
      id:6,
      img: 'assets/img/Trojan_Baterias.jpg',
      title: 'Baterías de Ciclo Profundo',
      description: 'Proveemos baterías de ciclo profundo para sistemas de paneles solares en diferentes capacidades.',
      newProduct: false,
      moreProducts: true
    },
    {
      id:7,
      img: 'assets/img/Paneles_Solares.jpg',
      title: 'Equipos para Sistemas de Paneles Solares',
      description: 'Proveemos paneles solares, controladores de carga e inversores.',
      newProduct: false,
      moreProducts: false
    },
    {
      id:8,
      img: 'assets/img/Turbina.jpg',
      title: 'Generadores Hidroeléctricos',
      description: 'Proveemos generadores hidroeléctricos. Somos distribuidores de la marca Turbolent para Honduras.',
      newProduct: false,
      moreProducts: false
    }
  ]

  watergenProducts: Array<Product> = [
    {
      id: 1,
      brand: 'watergen',
      tumblImg: 'assets/img/watergen/GENNY-final-300x240.jpg',
      img: 'assets/img/watergen/GENNY-final.jpg',
      title: 'GENNY',
      shortDescriptionTitle: 'Hasta 30 litros (por día)',
      shortDescription: 'Agua fresca del grifo. No se requieren plomería, recargas ni infraestructura: simplemente conéctelo y comience a beber.',
      subTitle: 'Agua del aire, en casa o en la oficina',
      description1: 'Renuncie a esas botellas de plástico y libere espacio en el refrigerador - GENNY le brinda agua fresca y fresca del grifo. No se requieren plomería, recargas o infraestructura - simplemente conecte este pequeño generador de agua del aire en su hogar u oficina para obtener <b>hasta 30 litros por día</b>.',
      description2: 'Genny utiliza un sistema de purificación y circulación de agua de múltiples etapas de última generación para garantizar una <b>calidad de agua superior</b>. El robusto proceso de filtración de aire del sistema, diseñado para operar en condiciones de alta contaminación del aire, ventila aire seco ultra limpio de regreso a la habitación, lo que lo hace ideal para familias.',
      fileName: 'Genny-Specification.pdf'
    },
    {
      id: 2,
      brand: 'watergen',
      tumblImg: 'assets/img/watergen/GEN-M-final-300x240.jpg',
      img: 'assets/img/watergen/GEN-M-final.jpg',
      title: 'GEN-M',
      shortDescriptionTitle: 'Hasta 800 litros (por día)',
      shortDescription: 'Proporcione a su escuela, centro comercial, edificio residencial u hospital su propia fuente de agua renovable en el lugar.',
      subTitle: 'Generador de agua atmosférica de mediana escala, genera hasta 800 litros de agua limpia todos los días.',
      description1: 'Escuelas, hospitales, edificios comerciales y residenciales y más ahora pueden aprovechar su propia fuente de agua renovable en el lugar. Sin necesidad de otra infraestructura que el suministro eléctrico, el GEN-M es un generador de agua móvil de mediana escala, con una capacidad diaria de 800 litros.',
      description2: 'El GEN-M es el módulo generador de agua atmosférica (AWG) más eficiente en energía del mundo de su tipo, gracias al GENius patentado de Watergen, que le permite generar más agua mientras minimiza el consumo de electricidad. A diferencia de otras soluciones AWG que se basan en una tecnología de aire acondicionado antigua e ineficiente, GENius incluye un intercambiador de calor único en su tipo, lo que lo hace más económico y eficiente de operar.',
      description3: 'Un módulo integrado de optimización de energía del GEN-M optimiza continuamente la producción de agua de acuerdo con las condiciones atmosféricas externas (día / noche, estación, ubicación geográfica, altitud, etc.). Una cascada de filtración de aire de múltiples barreras asegura que el agua generada sea de alta calidad, independientemente de la calidad del aire, y un depósito incorporado y una instalación de tratamiento hacen circular el agua continuamente, manteniéndola fresca con el tiempo.',
      fileName: 'GEN-M-Unit-Specification-1.pdf'
    },
    {
      id: 3,
      brand: 'watergen',
      tumblImg: 'assets/img/watergen/GEN-L-final-300x240.jpg',
      img: 'assets/img/watergen/GEN-L-final.jpg',
      title: 'GEN-L',
      shortDescriptionTitle: 'Hasta 5000 litros (por día)',
      shortDescription: 'Perfecto para pueblos, asentamientos fuera de la red y fábricas.',
      subTitle: 'Genere hasta 5,000 litros de agua limpia todos los días',
      description1: 'Esta solución de enchufar y beber es una fuente sólida y renovable de agua potable fresca y limpia, que no requiere ninguna otra infraestructura que el suministro de electricidad. Ha sido diseñado para satisfacer las necesidades de pueblos, fábricas y asentamientos fuera de la red.',
      description2: 'Para garantizar la alta calidad del agua generada y mantener la frescura y la pureza independientemente de la calidad del aire, un sistema de filtración de aire de barrera múltiple incorporado elimina las micropartículas y los rastros orgánicos y agrega minerales esenciales.',
      description3: 'Cada generador de agua GEN-L está construido como una unidad modular, por lo que, a medida que los requisitos de agua crecen y cambian, se pueden integrar rápida y fácilmente con otras unidades para crear granjas de generación de agua.',
      fileName: 'Gen-L-Specification.pdf'
    },
    {
      id: 4,
      brand: 'watergen',
      tumblImg: 'assets/img/watergen/GEN-M-ERV-final2-300x171.jpg',
      img: 'assets/img/watergen/GEN-M-ERV-final2.jpg',
      title: 'GEN-M ERV',
      shortDescription: 'Vehículo de respuesta a emergencias compuesto por dispensadores GEN-M, diesel y agua que brindan agua potable de alta calidad en movimiento en situaciones de emergencia y rescate',
      subTitle: 'Llevando agua limpia y fresca a los sitios de grandes emergencias y desastres naturales.',
      description1: 'Cuando ocurre un incidente de emergencia o un desastre natural, los suministros de agua a menudo se ven comprometidos o cortados por completo.',
      description2: 'Nuestros camiones de servicio pesado personalizados transportan de forma segura unidades Generador de agua atmosférica GEN-M de 800 litros de capacidad a través de cualquier terreno, para proporcionar agua potable limpia directamente a las personas cuando más la necesitan.',
      description3: 'Con GEN-M ERV, el agua está disponible casi de inmediato, lo que la convierte en la solución más rápida y fácil de implementar en los casos en que el tiempo es un problema.'
    },
    {
      id: 5,
      brand: 'watergen',
      tumblImg: 'assets/img/watergen/GEN-M-Mobile-Trailer-final2-300x171.jpg',
      img: 'assets/img/watergen/GEN-M-Mobile-Trailer-final2.jpg',
      title: 'GEN-M Mobile Trailer',
      shortDescription: 'Una solución de agua móvil ideal para incidentes de emergencia o desastres naturales, compuesta por un remolque de servicio pesado, Watergen GEN-M y un generador diesel.',
      subTitle: 'El remolque móvil GEN-M es un remolque de servicio pesado personalizado equipado con nuestro generador de agua atmosférica Gen-M',
      description1: 'El remolque móvil GEN-M de Watergen incluye una unidad GEN-M que genera hasta 800 litros de agua limpia al día. Una solución móvil después de un incidente de emergencia o desastre natural, el remolque móvil GEN-M puede satisfacer la necesidad humana más básica de beber limpio Se incluye un generador diesel de 12kVA con el Remolque Móvil, así como 2 dispensadores en lados opuestos, lo que permite que más personas accedan al agua simultáneamente.'
    },
    {
      id: 6,
      brand: 'watergen',
      tumblImg: 'assets/img/watergen/car-edited-300x171.png',
      img: 'assets/img/watergen/car-edited.png',
      title: 'Automotive Solution',
      subTitle: 'La innovadora tecnología de agua a partir del aire de Watergen, ahora disponible en una nueva solución, diseñada para uso en vehículos.',
      shortDescription: 'Tecnología innovadora de agua desde el aire, ahora disponible en una nueva solución, diseñada para uso en vehículos.',
      description1: 'La tecnología patentada que se desarrolló originalmente para ayudar a resolver la crisis de escasez de agua en el mundo ahora se ha adaptado para su uso en automóviles privados, vehículos recreativos y de compromiso, autobuses, camiones y furgonetas.'
    }
  ]

  trojanProducts: Array<Product> = [
    {
      id: 1,
      brand: 'trojan',
      img: 'assets/img/trojan/27tmx.jpeg',
      title: '27tmx 105Ah',
      shortDescription: '27TMX Trojan, batería inundada, 12V, 105Ah @ 20Hr, Grupo 27, Wingnut Terminal, 27TMX Las aplicaciones de energía renovable operan bajo condiciones desafiantes.',
      subTitle: 'Batería de ciclo profundo marca Trojan',
      description1: 'La Batería TROJAN 27TMX 105Ah 12V es una batería de 12V de tensión, de tipo monobloc porque está compuesta por 6 celdas de 2V entregando una tensión total de 12V. En las familia de las baterías de plomo, las de plomo-ácido abierto son las más económicas y que mayor cantidad de amperios pueden proporcionar por cada céntimo que cuestan. Las hay de todas las gamas, pero por lo general suelen ser en su mayoría modelos de acceso y la vida útil de las mismas es de las más limitadas.',
      fileName: '27TMX_Trojan_Data_Sheets.pdf'
    },
    {
      id: 2,
      brand: 'trojan',
      img: 'assets/img/trojan/t-605.jpeg',
      title: 'T 605',
      shortDescription: 'La Batería Trojan T-605 6V 210Ah posee un diseño específicamente creado para aguantar las descargas más profundas y soportar niveles de exigencia que otras baterías no son capaces de alcanzar.',
      subTitle: 'Batería de ciclo profundo marca Trojan',
      description1: 'Trojan T-605 es una batería de ciclo profundo de tecnología Plomo abierto, Con mantenimiento para uso industrial y energías renovables con una capacidad de 230Ah a 6V de tensión. Trojan es el principal fabricante mundial de baterías de ciclo profundo de plomo ácido de electrolito líquido, GEL y AGM.',
      fileName: 'T605_Trojan_Data_Sheets.pdf'
    },
    {
      id: 3,
      brand: 'trojan',
      img: 'assets/img/trojan/ssig-06-475.jpeg',
      title: 'SSIG 06 475',
      shortDescription: 'Batería plomo ácido Trojan Solar Signature SSIG 06 475 6V 428Ah Ciclo profundo 1200 Ciclos',
      subTitle: 'Batería de ciclo profundo marca Trojan',
      description1: 'Más grande que la batería tipo carrito de golf T-105, sus placas de plomo son dos veces más gruesas para resistir más abusos y durar más. Los terminales tipo bandera permiten la conexión de cables grandes. Incluye asas para levantarlo fácilmente. Utilice pares de estas baterías en serie para la configuración de 12V, juegos de cuatro para 24V y así sucesivamente. La esperanza de vida es de 5 a 10 años, asumiendo 300 ciclos al 80% de profundidad de descarga. Agregue estos juegos de baterías en paralelo para aumentar los amperios-hora. Cada conjunto (o cada batería) tiene una potencia nominal de 390 amperios-hora.',
      fileName: 'SSIG_06_475_DS.pdf'
    }
  ]

  constructor() { }

  getProducts() {
    return [...this.products]
  }

  getProductById(id: number) {
    let productSelected: Products
    for(let product of this.products) {
      if(product.id === id) {
        productSelected = product
      }
    }
    return productSelected
  }
  
  getWatergenProducts() {
    return [...this.watergenProducts]
  }
  
  getTrojanProducts() {
    return [...this.trojanProducts]
  }

  getWatergenProductById(id: number) {
    let productSelected: Product 
    for(let product of this.watergenProducts) {
      if(product.id === id) {
        productSelected = product
      }
    }
    return productSelected
  }

  getMoreProductsByTitle(title: string) {
    if(title === 'Generadores de Agua') {
      return this.getWatergenProducts()
    }
    if(title === 'Baterías de Ciclo Profundo'){
      return this.getTrojanProducts()
    }
  }

  getProductByBrandAndName(brand: string, productName: string) {
    let productSelected: Product
    if(brand === 'Watergen' || brand === 'watergen') {
      for(let product of this.watergenProducts) {
        if(product.title === productName) {
          productSelected = product
        }
      }
    }
    if(brand === 'Trojan' || brand === 'trojan') {
      for(let product of this.trojanProducts) {
        if(product.title === productName) {
          productSelected = product
        }
      }
    }
    return productSelected
  }
}
