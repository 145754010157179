import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DbService } from 'src/app/services/db.service';
import { Product } from 'src/app/models/product';

@Component({
  selector: 'app-more-products',
  templateUrl: './more-products.component.html',
  styleUrls: ['./more-products.component.css']
})
export class MoreProductsComponent implements OnInit {

  productTitle = ''
  moreProducts: Array<Product> = []

  constructor(private activatedRoute: ActivatedRoute, private dbService: DbService) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(paramMap => {
      this.productTitle = paramMap.get('title')
      this.moreProducts = this.dbService.getMoreProductsByTitle(this.productTitle)
    })
  }

}
