import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/models/product';
import { ActivatedRoute, Router } from '@angular/router';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-view-product',
  templateUrl: './view-product.component.html',
  styleUrls: ['./view-product.component.css']
})
export class ViewProductComponent implements OnInit {

  productTitle: string = ''
  product: Product

  constructor(private activatedRoute: ActivatedRoute, private dbService: DbService, private router: Router) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(paramMap => {
      this.productTitle = paramMap.get('title')
      let brand = paramMap.get('brand')
      let product = paramMap.get('product')
      this.product = this.dbService.getProductByBrandAndName(brand, product)
    })
  }

  backButton() {
    this.router.navigate([`/products/${this.productTitle}`])
  }

}