import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Mail } from '../models/mail';

@Injectable({
  providedIn: 'root'
})
export class MailingService {

  httpLink = 'https://dihsel.herokuapp.com'
  httpLink1 = 'http://localhost:3000'

  constructor(private http: HttpClient) { }

  sendEmail(mail: Mail) {
    return this.http.post<Mail>(`${this.httpLink}/api/send-cotization`, mail)
  }

  sendContact(contact: Mail) {
    return this.http.post<Mail>(`${this.httpLink}/api/send-contact`, contact)
  }
}