import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { ProductsComponent } from './pages/products/products.component';
import { CotizationComponent } from './pages/cotization/cotization.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { MoreProductsComponent } from './pages/more-products/more-products.component';
import { ViewProductComponent } from './pages/view-product/view-product.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'products',
    component: ProductsComponent
  },
  {
    path: 'product-cotization/:id',
    component: CotizationComponent
  },
  {
    path: 'product-cotization/:brand/:title',
    component: CotizationComponent
  },
  {
    path: 'products/:title',
    component: MoreProductsComponent
  },
  {
    path: 'products/:title/:brand/:product',
    component: ViewProductComponent
  },
  {
    path: 'contact-us',
    component: ContactUsComponent
  },
  {
    path: 'about-us',
    component: AboutUsComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
