import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  apiLoaded = false;
  dihselVideo//: HTMLVideoElement

  constructor() { }

  ngOnInit() {
    this.dihselVideo = document.getElementById('dihselVideo')
    this.dihselVideo.muted = true
    this.dihselVideo.play()
  }

}